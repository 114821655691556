import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="float-and-clear" mdxType="Subpage">
      <h3>{`Float and Clear`}</h3>
      <Callout number="91." term="Float and clear..." color="alternate" mdxType="Callout">
        <p>{`Are helpful CSS properties that begin to assist with multi-column layouts. The "natural float" is the best use for floats today.`}</p>
      </Callout>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read Ch. 15 from Duckett`}</p>
        <small>for a survey of techniques used to recreate layouts with HTML and CSS. This chapter surveys a number of topics that relate to how to build layouts with HTML and CSS. However, many things have changed in the last year or so with which this book has not yet caught up.</small>
      </Callout>
      <p>{`The most meaningful use for the float property today is to accomplish a "natural float:" simple effect of have an image or box set off to the side of a container while the surrounding text flows around it, as in traditional print media.`}</p>
      <p><img parentName="p" {...{
          "src": "/images/natural-float.png",
          "alt": null
        }}></img></p>
      <p>{`Simply select the target image or group and set it to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`float:left`}</code>{` or `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`float:right`}</code>{`. Likewise, if something is floating that you want to instruct not to float anymore, cancel that setting with `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`float:none`}</code>{`. Finally, if you want some element after the floating element NOT to wrap around it, set that other element to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`clear:both`}</code>{`.`}</p>
    </Subpage>
    <Subpage slug="flexbox" mdxType="Subpage">
      <h3>{`Layouts with Flexbox`}</h3>
      <Callout number="9.2" term="Flexbox..." color="alternate" mdxType="Callout">
        <p>{`...is an emerging tool we can use to create multicolumn layouts for elements inside a container.`}</p>
      </Callout>
      <p>{`An emerging and stable tool to use for creating multi-column layouts today is the flexbox technology.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read `}<a parentName="p" {...{
            "href": "https://css-tricks.com/snippets/css/a-guide-to-flexbox/"
          }}>{`a very nice explanation of Flexbox`}</a>{` from Chris Coyier at CSS Tricks.`}</p>
      </Callout>
      <p>{`Here is a basic process for using flex box:`}</p>
      <ul>
        <li parentName="ul">{`Ensure that a container exists around all the "columns" and set it to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`display:flex`}</code>{`.
Each item directly inside that container will now tile up side-by side.`}</li>
        <li parentName="ul">{`Control dimensions of the items using one of the following:`}<ul parentName="li">
            <li parentName="ul">{`Set an explicit `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`width`}</code>{` such as `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`300px`}</code>{`.`}</li>
            <li parentName="ul">{`Set a relative `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`width`}</code>{` such as `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`50%`}</code>{`.`}</li>
            <li parentName="ul">{`Set a flex box size ratio instead of width using the `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`flex`}</code>{` property and a value such as `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`1`}</code>{`, `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`2`}</code>{`, `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`5`}</code>{`, etc. More on this below.`}</li>
          </ul></li>
        <li parentName="ul">{`Add other settings to further tweak the configuration (see section below).`}</li>
      </ul>
    </Subpage>
    <Subpage slug="flex-ratio" mdxType="Subpage">
      <h4>{`Flexbox `}<code parentName="h4" {...{
          "className": "language-text"
        }}>{`flex`}</code>{` ratio-based sizing`}</h4>
      <Callout number="9.3" term="'flex' sizing..." color="alternate" mdxType="Callout">
        <p>{`...is powerful and can be simple, ratio-based sizing, but might not always be practical to calculate.`}</p>
      </Callout>
      <p>{`The flex box `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`flex`}</code>{` property is the most flexible and simple way to provide width settings for elements in a single row. The idea is to provide a ratio that describes each element's size compared to the others. Here's the idea:`}</p>
      <ul>
        <li parentName="ul">{`If all elements are supposed to be the same size set them to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`flex:1`}</code></li>
        <li parentName="ul">{`If there are two elements and one is 1/3 of the overall width while the other is 2/3 of the overall width, set the smaller one to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`flex:1`}</code>{` and the larger one to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`flex:2`}</code>{`.`}</li>
      </ul>
      <p>{`In the second example above you can see how the ratio of thirds led to the flex setting: one was `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`1`}</code>{` third and the other was `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`2`}</code>{` thirds.`}</p>
      <p>{`If your elements are a more complex ratio than this or if there are more than two elements that are not all the same size you might be better off using percentages or pixels. Otherwise you need a more complex process:`}</p>
      <ol>
        <li parentName="ol">{`Measure the width of the overall row and write it down.`}</li>
        <li parentName="ol">{`Measure the width of each element and write them down.`}</li>
        <li parentName="ol">{`Calculate each element's ratio of the total size by dividing its width by the overall width from step 1. For example if the overall width is 900 pixels and one element inside that width is 400 pixels its ratio is 0.444.`}</li>
        <li parentName="ol">{`Refer to this table to see if all elements can be rounded closely to one of the columns and use the corresponding flexible ration number. If not, then multiply all the ratios by one hundred and round them to the nearest whole number. In our case, 0.444 becomes 44. Use this for the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`flex`}</code>{` value.`}</li>
      </ol>
    </Subpage>
    <Subpage slug="flexbox-settings" mdxType="Subpage">
      <h4>{`Other Flexbox settings`}</h4>
      <Callout number="9.4" term="Flexbox includes many other settings..." color="alternate" mdxType="Callout">
        <p>{`...but `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`flex-flow`}</code>{` and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`justify-content`}</code>{` provide settings for two very common situations.`}</p>
      </Callout>
      <p>{`While there are many helpful ways to configure flex box elements, here are some you might find commonly useful:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`flex-flow`}</code>{`: Set this to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`row wrap`}</code>{` or to easily create tiling elements. In this case, it will be best to set the size of the individual items using pixel or percentage widths rather than `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`flex`}</code>{`.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`justify-content`}</code>{`: Use this to align a set of flex elements...`}<ul parentName="li">
            <li parentName="ul">{`to the left with `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`flex-start`}</code>{`.`}</li>
            <li parentName="ul">{`to the right with `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`flex-end`}</code>{`.`}</li>
            <li parentName="ul">{`centered with `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`center`}</code>{`.`}</li>
            <li parentName="ul">{`with equal space separating them with `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`space-between`}</code>{`. This is great for when you want elements on outer edges of a row flush with the outer edges and even spacing between elements otherwise.`}</li>
            <li parentName="ul">{`with equal space around them with `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`space-around`}</code>{`. In contrast to `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`space-between`}</code>{` this adds even spacing between elements, but by ensuring the same space is added on both sides of the elements, creating a gutter between them and half that gutter on the outer elements in a given "row".`}</li>
          </ul></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`align-items`}</code>{`: Use this to adjust the default setting that can result in stretching items to the same height. Set it to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`flex-start`}</code>{`, for example, to ensure all elements hang from a common top line but are otherwise as tall as they naturally would be without flex box.`}</li>
      </ul>
      <p>{`Watch this demonstration of how to create content styles based on all we've discussed so far in this lesson:`}</p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/xI862wjBpIA" frameborder="0" allowfullscreen></iframe>
    </Subpage>
    <Subpage slug="future-of-layouts" mdxType="Subpage">
      <h3>{`Future of Layouts`}</h3>
      <p>{`As the web grows and designers and developers desire new, easier ways to accomplish things we can expect the techniques and technologies themselves to change.`}</p>
      <p>{`On the horizon for full adoption by the browser developers is the CSS Grid Module, which will radically change the way we build layouts in a positive way. Professor Schanely's MFA thesis project explored this topic and a process to use it well. Since the technology is not fully operational we'll teach a similar process that makes use of flex box in the following modules after some guided practice with it in this module.`}</p>
      <p>{`As a head's up for how we handle the many changes in the web during your time studying here, our web curriculum at Cedarville includes a class dedicated to catching up on current trends: Web Design III. As one interested in this field it will serve you well to develop a mindset for being a lifelong learner.`}</p>
    </Subpage>
    <Subpage slug="study-tools" mdxType="Subpage">
      <h3>{`Study Tools`}</h3>
      <p>{`Memorize each of the following CSS properties or values:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`float`}</code>{` and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`clear`}</code>{` and when they are helpful.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`display:flex`}</code>{` and where to apply it. The following allow us to further customize a flex box implementation and are applied to the same element to which `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`display:flex`}</code>{` is applied:`}<ul parentName="li">
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`justify-contents`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`flex-flow`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`align-items`}</code></li>
          </ul></li>
      </ul>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      